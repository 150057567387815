import * as React from "react";
import LogoSvg from "../images/logo_invert.svg";
import {contacts, trackEventWrap} from "../common";
import {Link} from "gatsby";
import Container from 'react-bootstrap/Container';
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TrackLink from "./TrackLink";
import "./HeaderJumbotron.scss";
import SocialIcons from "./SocialIcons";
import ModalFormCallOrder from "./ModalFormCallOrder";


const HeaderJumbotron = ({h1, h1Sub, bgImg, bgImgWebp, bgImgAlt, withPhoneButton, modalSubject, modalTitle}) => {
  return (
    <header className={"header-jumbotron"}>
      <picture>
        <source srcSet={bgImgWebp} type="image/webp"/>
        <source srcSet={bgImg} type="image/png"/>
        <img src={bgImg} className={"header-jumbotron-bg"} alt={bgImgAlt} />
      </picture>

      <Container className={"header-jumbotron-top"}>
        <Link to="/" className="logo">
          <img src={LogoSvg} alt={"логотип студии детейлинга Autozorgo"}/>
        </Link>
        
        <Link to="#yandex-map" onClick={trackEventWrap("ScrollToYandexMap", "Click", "", true)}>
          {contacts.address.city}, <span>{contacts.address.address}</span>
        </Link>
        
        {
          contacts.phone.length > 0 &&
          <TrackLink {...contacts.phone[0]} track={["Phone", "Click", contacts.phone[0].value]}/>
        }
      </Container>
      
      <Container>
        <h1>{h1}</h1>
        <p className="h5">{h1Sub}</p>
        {
          withPhoneButton &&
          contacts.phone.length > 0 && 
          <Row className="w-100">
            <Col xs={12} md={6} className="text-center text-md-end">
              <TrackLink {...contacts.phone[0]} className="btn btn-primary" track={["Phone", "Click", contacts.phone[0].value]} style={{width: 190}}>
                Позвонить
              </TrackLink>
            </Col>
            
            <Col xs={12} md={6} className="text-center text-md-start">
              <ModalFormCallOrder subject={modalSubject} title={modalTitle}>
                <Button variant={'primary'}>
                  Заказать звонок
                </Button>
              </ModalFormCallOrder>
            </Col>
          </Row>
        }
      </Container>

      <SocialIcons id="main-social-icons" vertical/>
    </header>
  );
};

export default HeaderJumbotron;