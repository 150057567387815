import * as React from "react"
import { contacts, trackEventWrap } from "../common";
import InstagramSVG from "../images/instagram.svg"
import ViberSVG from "../images/viber.svg"
import TelegramSVG from "../images/telegram.svg"

import "./SocialIcons.scss"


const SocialIcons = ({ id, vertical }) => {
  return (
    <div className={`social-icons ${vertical ? 'social-icons__vertical' : ''}`} id={id}>
      <ul className="shadow">
        <li className="social-icons_instagram" onClick={trackEventWrap("Social", "Click", "instagram")}>
          <a href={contacts.social.instagram} target="_blank" rel="noopener noreferrer">
            <img src={InstagramSVG} alt="instagram"/>
          </a>
        </li>

        <li className="social-icons_viber">
          <a href={contacts.social.viber} target="_blank" rel="noopener noreferrer" onClick={trackEventWrap("Social", "Click", "viber")}>
            <img src={ViberSVG} alt="viber"/>
          </a>
        </li>

        <li className="social-icons_telegram">
          <a href={contacts.social.telegram} target="_blank" rel="noopener noreferrer" onClick={trackEventWrap("Social", "Click", "telegram")}>
            <img src={TelegramSVG} alt="telegram"/>
          </a>
        </li>
      </ul>
    </div>
  )
}


export default SocialIcons;
