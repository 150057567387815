import * as React from "react"
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HeaderJumbotron from "../components/HeaderJumbotron";
import HeaderImage from "../images/header-cert.jpg";
import HeaderImageWebp from "../images/header-cert.jpg.webp";
import CertAImage from "../images/cert-a.jpg";
import CertAImageWebp from "../images/cert-a.jpg.webp";
import CertBImage from "../images/cert-b.jpg";
import CertBImageWebp from "../images/cert-b.jpg.webp";
import withLayout from "../hoc/withLayout";
import Yandex from "../components/Yandex";
import Footer from "../components/Footer";
import { contacts } from "../common";
import "./cert.scss";


const CertPage = () => {
  const org = contacts.org;

  return (
    <>
      <HeaderJumbotron
        h1="Правила пользования сертификатом" 
        h1Sub=""
        bgImg={HeaderImage} bgImgWebp={HeaderImageWebp}
        bgImgAlt="мойка автомобиля"
      />

      <main className="cert-page">
        <Container className="pb-5">
          <Row>
            <Col sm={12} md={12} lg={12} xl={6} xxl={6}>
              <a href={CertAImage} target="_blank" rel="noreferrer">
                <picture className="picture-cert">
                  <source srcSet={CertAImageWebp} type="image/webp" />
                  <source srcSet={CertAImage} type="image/jpg" />
                  <img src={CertAImage} alt=""/>
                </picture>
              </a>
            </Col>

            <Col sm={12} md={12} lg={12} xl={6} xxl={6}>
              <a href={CertBImage} target="_blank" rel="noreferrer">
                <picture className="picture-cert">
                  <source srcSet={CertBImageWebp} type="image/webp" />
                  <source srcSet={CertBImage} type="image/jpg" />
                  <img src={CertBImage} alt=""/>
                </picture>
              </a>
            </Col>
          </Row>

          <h2 className="mb-5 mt-5">Условия использования данного сертификата:</h2>
        
          <p className="fs-4">
            1. Срок действия подарочных сертификатов составляет 3 месяца с даты приобретения.
          </p>

          <p className="fs-4">
            2. Срок действия подарочного сертификата не продлевается. 
            По истечении срока, указанного в подарочном сертификате, ООО "СалютМоторс Плюс" вправе отказать держателю оказании услуг.
          </p>

          <p className="fs-4">
            3. В случаях утраты, кражи, порчи подарочного сертификата, не позволяющих его идентифицировать, 
            такой подарочный сертификат не восстанавливается, не обменивается на новый, денежные средства, 
            равные номинальной цене подарочного сертификата, возврату не подлежат.
          </p>

          <p className="fs-4">Подробнее об использовании сертификатов - в <a href="/cert_terms.pdf" target="_blank" rel="noreferrer">Положении</a>.</p>
        </Container>
        
        <Yandex/>

        <Container className="pt-5 pb-5">
          <ul className="list-unstyled">
            <li><strong>{org.name}</strong></li>
            <li><strong>Юридический адрес: </strong>{org.address}</li>
            <li><strong>Почтовый адрес: </strong>{org.post}</li>
            <li><strong>УНП: </strong>{org.unp}</li>
            <li><strong>Р/с: </strong>{org.bankAccount}<br/>{org.bank}, БИК: {org.bik}<br/>({org.bankAddress})</li>
            <li><strong>Директор: </strong>{org.manager}</li>
            <li><strong>Телефон: </strong>{org.phone}</li>
          </ul>
        </Container>
      </main>

      <Footer/>
    </>
  )
}


export default withLayout(CertPage,
  "Правила пользования сертификатом",
  "Правила пользования сертификатом",
)